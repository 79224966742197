<template>
  <div class="container">
    <div>
      <iframe id="myIframe" ref="iframeModel" :src="url" style="width: 100%; height: 93vh" frameborder="0"> </iframe>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  data() {
    return {
      active: "1",
      liveId: null,
      showList: true,
      reload: false,
      url: "http://aiszr.oouu.cc/?token=" + localStorage.getItem("token") + "&timestamp=<%= new Date().getTime() %>",
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r) => {
        console.log("resourcePath:", r);
      });
    },
    select(e) {
      this.active = e;
    },
    add(id) {
      this.liveId = id + "";
      this.showList = !this.showList;
      if (this.showList) {
        console.log(123);
        this.$refs.liveList.getlive();
      }
    },
  },
};
</script>
<style scoped>
v-deep.is-active {
  color: #fff;
  background: #409eff;
}
</style>
